@import '../../styles/index';

.search-box {
  z-index: 1;
  background-color: $color-white--100;

  @include media-from(sm) {
    top: 6rem;
    left: 1rem;
    position: absolute;
  }
}
