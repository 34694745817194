@import '../../styles/index';

.base {
  animation: scale-in $duration-default 1 forwards;
  transform-origin: bottom center;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  user-select: none;
  transform: translate(-50%, -100%);
  z-index: 10;
  cursor: pointer;
  color: $color-surrogate--100;

  @include reduced-motion {
    animation: none;
  }

  & > svg,
  & > img {
    position: relative;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    width: 50px;
    height: 50px;
  }

  &:hover {
    z-index: $index-map-cluster;
  }
}

@keyframes scale-in {
  from {
    transform: translate(-50%, -100%) scale(0);
  }

  to {
    transform: translate(-50%, -100%) scale(1);
  }
}

.span {
  align-items: center;
  background-color: $color-white--100;
  border-radius: 50%;
  display: flex;
  font-size: 0.75rem;
  height: 1.5rem;
  justify-content: center;
  position: absolute;
  left: 50%;
  top: 38%;
  transform: translate(-50%, -50%);
  width: 1.5rem;
  font-weight: $font-weight-bold;
  z-index: $index-map-cluster-span;
  line-height: 1.5;
  font-family: 'Euclid Enercity', sans-serif;
}

.surrogate {
  color: $color-surrogate--100;
}

.yellow {
  color: $color-yellow--100;
}

.turquoise {
  color: $color-turquoise--100;
}

.white {
  color: $color-white--100;
}

.blue {
  color: $color-blue--100;
}

.gradient {
  & > svg path {
    fill: var(--gradient-fill-url, url(#linearGradient-enercity));
  }
}

.violet {
  color: $color-violet--100;
}

.orange {
  color: $color-orange--100;
}

.green {
  color: $color-green--100;
}

.lightblue {
  color: $color-blue--60;
}
