@import '../../styles/index';

.container {
  bottom: '0';
  position: 'absolute';
  width: '100%';
}

.close-button {
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 1.25rem;
  justify-content: center;
  margin-bottom: 0;
  position: fixed;
  right: 0.3rem;
  top: 0.5rem;
  width: 1.25rem;
}

.base {
  animation: scale-in $duration-default 1 forwards;
  background-color: $color-black--80;
  border-radius: $border-radius-default;
  color: $color-white--100;
  line-height: 1.2;
  max-width: 480px;
  overflow-y: scroll;
  position: absolute;
  width: calc(100% - 2rem);
  -webkit-overflow-scrolling: touch;
  bottom: 0;
  box-shadow: $shadow-standard;
  font-size: $font-size-16;
  left: 0;
  margin: 1rem;
  padding: 1rem;

  @include reduced-motion {
    animation: none;
  }

  &::-webkit-scrollbar {
    display: block;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    width: 10px;

    &-thumb {
      background-color: rgba(0, 0, 0, 0.2);
      border-radius: 5px;
      opacity: 0.5;
    }
  }

  & strong {
    font-size: 1.375rem;
    font-weight: 500;
  }

  & > * {
    margin: 0;

    &:not(:last-child) {
      margin-bottom: 1rem;
    }

    &.no-margin {
      margin-bottom: 0;
    }
  }
}

@keyframes scale-in {
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
}
