@import '../../styles/index';

.base {
  position: relative;
  width: 100%;
  min-height: 85vh;
  overflow: hidden;
  margin-bottom: 1rem;

  @include media-from(sm) {
    min-height: 50rem;
  }
}

.transparent {
  opacity: 0.7;
}
